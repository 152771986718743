import React, { useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Layout } from "../Layout";
import ProductBanner from "../product_banners/product_banners";
import Banner from "../../imgs/bg_buniversity.jpg";
import ArticulosRelacionados from "./articulos_relacionados";
import Button from "../buttons/button";
import Icon from "../icon/icon";
import Mail from "../../imgs/share_mail.svg";
import Twitter from "../../imgs/share_twitter.svg";
import Linkedin from "../../imgs/share_linkedin.svg";
import Whatsapp from "../../imgs/share_whatsapp.svg";
import '../../pages/articles.css';
import { Helmet } from "react-helmet";
import { apiRequest } from "../../apiRquest/apiRequest";

const ArticuloPage = ({ artid, slug, title, metatitle, description, imgurl, htmlcontent, timestamp, artDate }) => {
    const baseUrl = `${process.env.GATSBY_BASE_URL}`;
    const pageTitle = `${metatitle}`;
    const pageDesc = `${description}`;
    const imgUrl = imgurl;
    const shareUrl = encodeURIComponent(baseUrl + 'balanz-university/' + slug)
    const [articulosrel, setArticulosrel] = useState([])
    const variablehtml = "\u00B7"
    const data = useStaticQuery(graphql`
    {
      allArticulosJson {
        edges {
          node {
            slug
            title
            description
            category
            date
            artid
            imgUrl
            timestamp
          }
        }
      }
    }
`);
    useEffect(() => {
        apiRequest.get('/v1/university')
            .then(response => {
                const articulosrelFull = response.data.map(d => ({ node: d }));
                const articulosrelFiltered = articulosrelFull.filter(element => element.node.artid !== artid)
                const articulosrelsorted = articulosrelFiltered.sort((a, b) => new Date(b.node.timestamp) - new Date(a.node.timestamp));
                setArticulosrel(articulosrelsorted.filter((r, i) => i < 4))
            })

        // console.log(getReadingTime())

    }, [])

    useEffect(() => {
        getReadingTime(htmlcontent)
    },[])

    function getReadingTime(htmlcontent) {
        let words3 = htmlcontent;
        var words, time, minutes, seconds;
        words = words3.split(' ').length;
        time = (60 * words / 250)
        minutes = ~~(time / 60);
        seconds = Math.ceil(time - minutes * 60);
        if (seconds >= 30) {
            return `${minutes + 1}m`
        } else if (seconds < 30) {
            return `${minutes}m`
        }
    }

    const page = (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "BlogPosting",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "${process.env.GATSBY_BASE_URL + slug}"
                            },
                            "headline": "${title}",
                            "description": "${description}",
                            "image": "${imgurl}",
                            "author": {
                                "@type": "Organization",
                                "name": "Balanz",
                                "url": "https://balanz.com/"
                            },  
                            "publisher": {
                                "@type": "Organization",
                                "name": "",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": ""
                                }
                            },
                            "datePublished": "${timestamp}",
                            "dateModified": "${timestamp}"
                        }
                    `}
                </script>
            </Helmet>

            <ProductBanner
                headingType="h2"
                bannerContainerClassName="bannerUniversity"
                bannerTrasparecy="bannerMask"
                productTitle="Balanz University"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={null}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <section className="fullwidth blnz-article-container">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="blnz-article">
                                <Row className="row d-flex justify-content-between align-content-end art-header align-items-center">
                                    <Col xs={12} md={8} className="art-title order-2 order-md-1">
                                        <h1 className="main-title mt-2 mb-2">
                                            <span className="bigger-title">{title}</span>
                                        </h1>
                                        <h3 className="mt-2 mb-4">
                                            <span className="sub-title base-text">{artDate}
                                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="css-1xgr03x e3ftz9k0"><path d="M11.9963 4C13.5785 4 15.1252 4.46919 16.4408 5.34824C17.7564 6.22729 18.7818 7.47672 19.3873 8.93853C19.9928 10.4003 20.1512 12.0089 19.8426 13.5607C19.5339 15.1126 18.772 16.538 17.6531 17.6569C16.5343 18.7757 15.1089 19.5376 13.557 19.8463C12.0052 20.155 10.3966 19.9965 8.93481 19.391C7.473 18.7855 6.22358 17.7602 5.34452 16.4446C4.46547 15.129 3.99628 13.5823 3.99628 12C3.99871 9.87901 4.84234 7.84559 6.34211 6.34583C7.84187 4.84606 9.87529 4.00243 11.9963 4ZM11.9963 2C10.0185 2 8.08507 2.58649 6.44058 3.6853C4.79609 4.78412 3.51436 6.3459 2.75749 8.17317C2.00061 10.0004 1.80258 12.0111 2.18843 13.9509C2.57428 15.8907 3.52669 17.6725 4.92521 19.0711C6.32374 20.4696 8.10557 21.422 10.0454 21.8079C11.9852 22.1937 13.9959 21.9957 15.8231 21.2388C17.6504 20.4819 19.2122 19.2002 20.311 17.5557C21.4098 15.9112 21.9963 13.9778 21.9963 12C21.9963 10.6868 21.7377 9.38641 21.2351 8.17315C20.7326 6.95988 19.996 5.85748 19.0674 4.92889C18.1388 4.0003 17.0364 3.26371 15.8231 2.76117C14.6099 2.25863 13.3095 1.99998 11.9963 2Z" fill="currentColor"></path><path d="M15.561 16.972L11.003 12.414V6.004H13.003V11.586L16.975 15.558L15.561 16.972Z" fill="currentColor"></path></svg>
                                                {getReadingTime(htmlcontent)}</span>
                                        </h3>
                                    </Col>
                                    <Col xs={12} md={4} className="art-redes d-flex align-items-center order-1 order-md-2">
                                        <div
                                            style={{
                                                width: "100%",
                                                textAlign: "center"
                                            }}
                                            className="d-flex justify-content-md-end gx-4 justify-content-end justify-content-sm-center align-items-start icon-container "
                                        >
                                            <Icon
                                                src={Mail}
                                                alt="Mail-icon"
                                                className="Mail-footer footer-icons"
                                                href={`mailto:?subject=${title} || Balanz&body=${baseUrl}balanz-university/${slug}`}
                                            />
                                            <Icon
                                                src={Whatsapp}
                                                alt="Whatsapp-icon"
                                                className="whatsapp-footer footer-icons"
                                                data-action="share/whatsapp/share"
                                                href={`https://api.whatsapp.com/send?text=${shareUrl}`}
                                                target="_blank"
                                            />
                                            {/*<Icon
                                                src={Facebook}
                                                alt="Facebook-icon"
                                                className="facebook-footer footer-icons"
                                                href={'https://www.facebook.com/sharer/sharer.php?u='+baseUrl +'balanz-university/' + slug}
                                            />*/}
                                            <Icon
                                                src={Twitter}
                                                alt="Twitter-icon"
                                                className="twitter-footer footer-icons"
                                                href={'https://www.twitter.com/share?url=' + baseUrl + 'balanz-university/' + slug + '&text=' + title + '&via' + 'twitterHandle'}
                                            />
                                            <Icon
                                                src={Linkedin}
                                                alt="Linkedin-icon"
                                                className="linkedin-footer footer-icons"
                                                href={`https://www.linkedin.com/shareArticle?mini=true&url=${baseUrl}balanz-university/${slug}&title=${title}&summary=${title}&source=${baseUrl}`}
                                                target="_blank"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div
                                            className="article-content"
                                            dangerouslySetInnerHTML={{ __html: htmlcontent }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth bg-lt-grey">
                <Container>
                    <Row>
                        <Col xs={12}>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth blnz-article-container">
                <Container>
                    <h2 className="mb-2">Artículos relacionados</h2><br />
                    <ArticulosRelacionados
                        items={articulosrel}
                        section="balanz-university"
                        linkBtn="/balanz-university/articulos/"
                        getReadingTime={getReadingTime}
                    />
                </Container>
            </section>
            <section className="fullwidth semi inversiones py-lg-5 py-sm-4 py-3">
                <Container>
                    <Row className="gx-lg-5 gx-md-4 gx-3 gy-3" xs={1} md={2}>
                        <Col>
                            <article className="semiwidthbg Balanz-cursos p-lg-5 py-md-4 px-md-4 py-3 px-4">
                                <div className="section-content  mx-2">
                                    <h2 className="titulo"><span className="highlightsbold">Balanz University.</span> </h2>
                                    <p className="texto2">Educación financiera para el mundo real.</p>
                                    <p className="texto">Aprendé todo lo que tenés <br/>
                                    que saber para invertir mejor <br/> 
                                    y potenciar tus ahorros.</p>
                                    <div className="cta py-3 py-sm-2 py-md-1">
                                    <Button variant="secondary" text="Ver cursos" onClick={() => { window.open("https://balanzuniversity.com/", "_blank") }} />
                                    </div>
                                </div>
                            </article>
                        </Col>
                        <Col>
                            <article className="abrir-cuenta p-lg-5 py-md-4 px-md-4 py-3 px-4">
                                <div className="section-content mx-2">
                                    <h2 className="titulo" >Abrí hoy tu cuenta de inversión</h2>
                                    <p className="texto">Operá todos los instrumentos del mercado desde la plataforma más completa y segura.</p>
                                    <div className="cta py-3 py-sm-2 py-md-1">
                                        <a href="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1399" className="btn btn-secondary">Abrir cuenta</a>
                                        {/* <Button variant="secondary" text="Abrir cuenta" onClick={() => { "https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1399" }} /> */}
                                    </div>
                                </div>
                            </article>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} img={imgUrl} childrem={page} category={slug}></Layout>
        </main>
    );
};

export default ArticuloPage;