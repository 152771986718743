import React, {useEffect, useState} from "react";
import { apiRequest } from "../../apiRquest/apiRequest";
import ArticuloPage from "../articulos/articulo";
import Loading from "../loading/loading";
import Article404 from "./Article404";

const ArticleDetail = (props) => {
    const [data, setData] = useState(null);
    const [show404, setShow404] = useState(false);
    const {slug} = props;

    useEffect(() => {
        if (typeof slug === 'string') {
            apiRequest
                .get('/v1/university')
                .then((res) => {
                    const data = res.data;
                    const article = data.find(d => d.slug === slug);
                    if(typeof article === 'undefined'){
                        setShow404(true);
                    }else{
                        setData(article)
                    }
                })
                .catch((err) => {
                    setShow404(true);
                })
        } else {
            setShow404(true);
        }
    }, []);



    if (show404) return <Article404/>

    if (data) return <ArticuloPage
        artid={data.artid}
        slug={data.slug}
        title={data.title}
        metatitle={data.metatitle}
        description={data.description}
        imgurl={data.imgUrl}
        htmlcontent={data.htmlcontent}
        timestamp={data.timestamp}
        artDate={data.date}
    />

    return <Loading customClass="loading-searchresults"/>
}

export default ArticleDetail;