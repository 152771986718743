import React from "react";
import {Router} from "@reach/router"
import ArticleDetail from "../../components/balanz-university/ArticleDetail";

const UniversityIndex = () => {

    return (
        <Router basepath="/balanz-university">
            <ArticleDetail path="/:slug"/>
        </Router>
    );
};

export default UniversityIndex;